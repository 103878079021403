<template>
  <v-row>
    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obWarehouse.name" />
    </v-col>

    <v-col cols="12" lg="6" xl="4">
      <form-field-text v-model="obWarehouse.code" label="code" />
    </v-col>
  </v-row>
</template>

<script lang="ts">
import { Vue, Component, VModel } from "vue-property-decorator";
import { Warehouse } from "@planetadeleste/vue-mc-gw";

@Component
export default class WarehousesSettings extends Vue {
  @VModel({ type: Object, default: () => new Warehouse() })
  obWarehouse!: Warehouse;
}
</script>
